<template>
	<div class="order-list">
		
		<div class="bind-item">
			<h1>项目基础配置</h1>
			<el-form ref="form" label-width="80px" style="margin-right: 1rem;">
				<el-form-item label="项目名">
					<el-input v-model="configMap.projectName" placeholder="给本项目取名"></el-input>
				</el-form-item>
				<el-form-item label="联系官方">
					<el-input v-model="configMap.contact" placeholder="右上角用户里面,显示可以显示联系官方的方式"></el-input>
				</el-form-item>
				<el-form-item label="指南连接">
					<el-input v-model="configMap.documentUrl" placeholder="指南连接,右上角的指南连接"></el-input>
				</el-form-item>
				<el-form-item label="充值比例">
					<el-input v-model="configMap.coinPrice" placeholder="1元等于多少金币?"></el-input>
				</el-form-item>
				<el-form-item label="标题价格">
					<el-input v-model="configMap.titlesPrice" placeholder="批量生成标题时价格"></el-input>
				</el-form-item>
				<el-form-item label="文章价格">
					<el-input v-model="configMap.articlePrice" placeholder="生成一次文章金币数"></el-input>
				</el-form-item>
				<el-form-item label="图片价格">
					<el-input v-model="configMap.picturePrice" placeholder="生成一次图片金币数"></el-input>
				</el-form-item>
				<div  class="bind-sure" >
					<el-button type="primary" @click="setConfig('projectName', configMap.projectName, '项目名').then(
						() =>{setConfig('contact', configMap.contact, '联系方式').then(() => {
						
						setConfig('coinPrice', configMap.coinPrice, '充值价格').then(() => {
						setConfig('articlePrice', configMap.articlePrice, '文章价格').then(() => {
						setConfig('picturePrice', configMap.picturePrice, '图片价格')
						setConfig('titlesPrice', configMap.titlesPrice, '批量标题价格').then(() => {
						setConfig('documentUrl', configMap.documentUrl, '指南连接').then(() => {
						
						})})})})})})">设置</el-button>
				</div>
			</el-form>
		</div>
		
	</div>
</template>

<script>

export default {
	data() {
		return {
			
			configList:{},
			
			configMap:{
				gpt: {
					key: '',
					model: 'gpt-3.5-turbo',
					host: 'https://api.openai-proxy.com'
				},
				StabilityAi:{
					key: ""
				},
				officialWebUrl: '',
				officialWebKey: '',
				
				file: null,  // 保存选择的文件对象
				fileUrl: null,  // 保存选择的图片的 URL
				banner:{
					banner1File: null,
					banner2File: null,
					banner3File: null,
					banner1Url: null,
					banner2Url: null,
					banner3Url: null,
				},
				loginTitle:"",
				projectName:"",
				contact:'',
				documentUrl:"",
				coinPrice:'',
				titlesPrice:"",
				articlePrice:'',
				picturePrice:'',
				saasKey:'',
				saasDomain:'',
				
				pay:{
					wechatPay:{
						mchid:"",
						appid:"",
						apiKey:"",
						apiclient_key:"",
						apiclient_cert:""
					}
				}
			},
		};
	},
	
	watch: {
		// 监听图片的变化
		chargeRate(chargeRate) {
			// 只能是整数
			this.chargeRate = chargeRate.replace(/[^\d]/g, '')
		},
		articlePrice(articlePrice) {
			// 只能是整数
			this.articlePrice = articlePrice.replace(/[^\d]/g, '')
		},
		picturePrice(picturePrice) {
			// 只能是整数
			this.picturePrice = picturePrice.replace(/[^\d]/g, '')
		}
	},
	
	mounted() {
		this.getConfig()
	},
	
	methods: {
		openFileInput() {
			this.$refs.fileInput.click();  // 打开文件选择框
		},
		bannerOpenFileInput(index) {
			this.$refs['bannerInput'+index].click();  // 打开文件选择框
		},
		handleFileChange(event) {
			this.file = event.target.files[0];  // 保存选择的文件对象
			this.fileUrl = URL.createObjectURL(this.file);  // 生成选择的图片的 URL
		},
		handleBannerChange(index, event) {
			console.log(index, this.banner)
			this.banner['banner'+index+'File'] = event.target.files[0];  // 保存选择的文件对象
			this.banner['banner'+index+'Url'] = URL.createObjectURL(this.banner['banner'+index+'File']);  // 生成选择的图片的 URL
		},
		uploadBanner(index) {
			console.log(this.banner)
			if (this.banner['banner'+index+'File']) {
				const formData = new FormData();
				formData.append('file', this.banner['banner'+index+'File']);  // 将文件对象添加到 FormData
				
				// 发送 POST 请求上传文件
				this.$http.post('/common/upload', formData)
					.then(response => {
						this.logoUrl = response.url
						this.$http.post('/admin/common/setConfig', {
							key: 'banner.banner'+index+'Url',
							value: this.logoUrl,
							name: '登录页banner'+index
						}).then(res => {
							if (res.code === 0) {
								this.$message({
									message: "成功",
									type: "success"
								});
							}
						})
					})
			}
		},
		uploadLogo() {
			if (this.file) {
				const formData = new FormData();
				formData.append('file', this.file);  // 将文件对象添加到 FormData
				
				// 发送 POST 请求上传文件
				this.$http.post('/common/upload', formData)
					.then(response => {
						this.logoUrl = response.url
						this.$http.post('/admin/common/setConfig', {
							key: 'logo',
							value: this.logoUrl,
							name: 'Logo'
						}).then(res => {
							if (res.code === 0) {
								this.$message({
									message: "成功",
									type: "success"
								});
							}
						})
					})
			}
		},
		getConfig() {
			return this.$http.post('/admin/common/getConfig', {}).then(res => {
				this.configList = res.data.list ? res.data.list : []
				Object.keys(res.data.map).forEach((key) => {
					
					if (res.data.map[key] instanceof Object) {
						try {
							Object.keys(res.data.map[key]).forEach((subKey) => {
								this.configMap[key][subKey] = res.data.map[key][subKey]
							})
						} catch (e) {
							console.log(e)
						}
					} else {
						this.configMap[key] = res.data.map[key]
					}
				})
			})
		},
		
		setConfig(key, value, name) {
			if (!key || !value || !name) {
				this.$message.error('输入错误');
				return;
			}
			return this.$http.post('/admin/common/setConfig', {
				key: key,
				value: value,
				name: name
			}).then(res => {
				if (res.code === 0) {
					this.$message({
						message: "设置"+name+"成功",
						type: "success",
					});
				}
			})
		},
	},
};
</script>
<style lang="scss" scoped>
.bind-item {
	margin: 2rem 1rem;
	padding:1rem;
	border: solid 1px #eee;
	border-radius:1rem;
	h1 {
		margin: 1rem;
		text-align: center;
	}
	.bind-sure {
		display: flex;
		justify-content: center;
	}
	::v-deep .el-textarea__inner {
		height: 5rem !important;
	}
}
::v-deep .el-form-item__label {
	padding-right: 15px !important;
}
.logoUpload {
	display: flex;
	justify-content: center;
}
.logoImg {
	display: flex;
	justify-content: center;
	margin:1rem auto 2rem auto;
	border: solid 1px #eee;
	border-radius: 50%;
	max-width: 100px;
	text-align: center;
	img {
		max-width: 100%;
	}
}
.logoImgBanner {
	max-width: 100px;
}
.banner-upload {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin:1rem auto 2rem auto;
	border-radius: 50%;
	max-width: 100px;
	text-align: center;
	button {
		height: 2rem;
	}
	img {
		max-width: 100%;
	}
	.banner-button {
		display: flex;
		justify-content: center;
		align-content: center;
	}
	h3 {
		margin-bottom: 2rem;
	}
}
</style>
